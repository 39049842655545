import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { FunctionComponent } from "react";
import { Inistitution } from "../institution";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { UIConfigurationContext } from "../../common/contexts/ui-configurationContext";
import React from "react";
export const InistituteExamResults: FunctionComponent = (props) => {
  const uiConfigData = React.useContext(UIConfigurationContext);
  return (
    <Inistitution>
      <div>
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              sx={{ textAlign: "center" }}
            >
              {uiConfigData.program} School Wide result
            </Typography>

            <br />
            <div style={{ textAlign: "center" }}>
              <Button
              target="_blank" 
                href="https://oeeabhyas.blob.core.windows.net/tt-uploads/trsma/TRSMA-SAT(WITH SCHOOL NAME).pdf?sp=r&st=2023-03-20T07:04:50Z&se=2024-03-20T15:04:50Z&spr=https&sv=2021-12-02&sr=d&sig=RPqik6%2B6UtiVCXb35APKi08sXep3q%2BGBhCirQvrt0SU%3D&sdd=1"
                size="large"
                startIcon={
                  <FileDownloadIcon
                    fontSize="inherit"
                    sx={{ color: green[500] }}
                  />
                }
                sx={{ color: green[500] }}
              >
                Download
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              sx={{ textAlign: "center" }}
            >
              {uiConfigData.program} Top 3 Result
            </Typography>

            <br />
            <div style={{ textAlign: "center" }}>
              <Button
              target="_blank" 
                href="https://oeeabhyas.blob.core.windows.net/tt-uploads/trsma/TOP 3 TRSMA.pdf?sp=r&st=2023-03-20T07:04:50Z&se=2024-03-20T15:04:50Z&spr=https&sv=2021-12-02&sr=d&sig=RPqik6%2B6UtiVCXb35APKi08sXep3q%2BGBhCirQvrt0SU%3D&sdd=1"
                //onClick={handleDownloadClick}
                size="large"
                startIcon={
                  <FileDownloadIcon
                    fontSize="inherit"
                    sx={{ color: green[500] }}
                  />
                }
                sx={{ color: green[500] }}
              >
                Download
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </Inistitution>
  );
};
