export const UIConfigurationData = [
    {
        "pageTitle": "TRSMA | e-Abhyas",
        "tenantName": "trsma",
        "tenantId": "1",
        "program": "TRSMA",
        "loginPageImage":
            "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/TRSMA Banner.jpeg"
        ,
        "aboutProgram": "",
        "contactNumbers": [
            "+91 9030779982",
            "+91 7337449982"
        ],
        "downloads": [

            {
                "title": "Model Papers",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/MODEL_PAPERS.zip",
                "type": "zip",
                "name": "MODELPAPERS.zip"
            }, {
                "title": "SYLLABUS",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/TRASMASAT - SYLLABUS - 2023 - 24.pdf",
                "type": "pdf",
                "name": "SYLLABUS.pdf"
            }, {
                "title": "SAT Leaflet",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/TRSMA SAT 2023 Final.pdf",
                "type": "pdf",
                "name": "SATLeaflet.pdf"
            }, {
                "title": "PAYMENT SHEET",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/TRSMA PAYMENT SHEET.pdf",
                "type": "pdf",
                "name": "PAYMENT SHEET.pdf"
            }, {
                "title": "5th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/5TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "5th-Solutions.pdf"
            }, {
                "title": "6th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/6TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "6th-Solutions.pdf"
            }, {
                "title": "7th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/7TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "7th-Solutions.pdf"
            }
            , {
                "title": "8th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/8TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "9th-Solutions.pdf"
            }
            , {
                "title": "9th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/9TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "9th-Solutions.pdf"
            }
        ],
        "logoConfiguration": {
            "logoURL": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/TRSMA lOGO - 2023.jpg",
            "logoStyle": { paddingTop: 10, width: 142 },
            "altText": "TRSMA Exam"
        },
        "studentTemplate": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/StudentListTemplate.xlsx"
    },
    {
        "pageTitle": "APPUSMA | e-Abhyas",
        "tenantName": "appusma",
        "tenantId": "1",
        "program": "APPUSMA",
        "loginPageImage":
            "https://oeeabhyas.blob.core.windows.net/tt-public/appusma/APPUSMA Banner.jpeg"
        ,
        "aboutProgram": "",
        "contactNumbers": [
            "+91 9030779982",
            "+91 9491909982"
        ],
        "downloads": [

            {
                "title": "Model Papers",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/trsma/MODEL_PAPERS.zip",
                "type": "zip",
                "name": "MODELPAPERS.zip"
            }, {
                "title": "SYLLABUS",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/appusma/APPUSMASAT - SYLLABUS - 2023 - 24.pdf",
                "type": "pdf",
                "name": "SYLLABUS.pdf"
            }
            , {
                "title": "SAT Leaflet",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/appusma/APPUSMA SAT Final.pdf",
                "type": "pdf",
                "name": "SATLeaflet.pdf"
            } , {
                "title": "PAYMENT SHEET",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/appusma/APPUSMA PAYMENT SHEET.pdf",
                "type": "pdf",
                "name": "PAYMENT SHEET.pdf"
            }, {
                "title": "5th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/5TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "5th-Solutions.pdf"
            }, {
                "title": "6th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/6TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "6th-Solutions.pdf"
            }, {
                "title": "7th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/7TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "7th-Solutions.pdf"
            }
            , {
                "title": "8th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/8TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "9th-Solutions.pdf"
            }
            , {
                "title": "9th Solutions",
                "url": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/9TH - SAT - SOLUTION PAPER.pdf",
                "type": "pdf",
                "name": "9th-Solutions.pdf"
            }
          
        ],
        "logoConfiguration": {
            "logoURL": "https://oeeabhyas.blob.core.windows.net/tt-public/appusma/APPUSMA LOGO- 2023.jpg",
            "logoStyle": { paddingTop: 10, width: 142 },
            "altText": "APPUSMA Exam"
        },
        "studentTemplate": "https://oeeabhyas.blob.core.windows.net/tt-public/eabhyas/StudentListTemplate.xlsx"
    }
]