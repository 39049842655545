import {
    IUserLoginInfo,
    UserLoginControlState,
} from './instituteLoginModels';


export const getInstitueLoginControlState =
    (values: IUserLoginInfo): UserLoginControlState => {

        let controlState = new UserLoginControlState();
        controlState.userName.label.userName = 'User Name';
        controlState.passWord.label.passWord = 'Password';

        return controlState;
    };



