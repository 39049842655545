import * as React from "react";
import { FunctionComponent, Context, useEffect } from "react";
import { Route, Routes, HashRouter as Router } from "react-router-dom";
import Box from "@mui/material/Box";
import { Home } from "./components/home/home";
import { InistitutionProfile } from "./components/Inistitution/institute-profile/instituteProfile";
import { InistitutionRegistration } from "./components/Inistitution/institute-registration/instituteRegistration";
import { InstituteLogin } from "./components/login/instituteLogin";
import { InistitutionHome } from "./components/Inistitution/institute-home/inistituteHome";
import { InistituteExamResults } from "./components/Inistitution/institution-exam-results/institutionExamResults";
import { InistituteStudentUpload } from "./components/Inistitution/institution-students-upload/institutionStudentUpload";
import { InistituteStudentList } from "./components/Inistitution/institute-student-list/instituteStudentList";
import { StudentHome } from "./components/Student/StudentHome";
import { StudentExam } from "./components/Student/StudentExam";
import { FacilitatorHome } from "./components/facilitator/facilitator-home/FacilitatorHome";
import { FacilitatorInstitutionsList } from "./components/facilitator/facilitator-institution-list/FacilitatorInstitutionsList";
import { FacilitatorStudentList } from "./components/facilitator/facilitator-student-list/FacilitatorStudentList";
import { UIConfiguration } from "./components/common/models/ui-configuration-Models";
import UIConfigurationHelper from "./components/common/services/ui-configurationHelper";
import { UIConfigurationProvider } from "./components/common/contexts/ui-configurationContext";
import { ForgotPassword } from "./components/forgotPassword/forgotPassword";

const getProgramName = (): string => {
  let domainName = 'trsma';
  const hostName = window.location.hostname.toLowerCase();
  if (hostName !== "localhost") {
    domainName = hostName.replace('.eabhyasacademy.com', '');
  }
  return domainName;
}
export default function App() {
  const programName = getProgramName();
  var uiConfigData = UIConfigurationHelper.get(programName);
  if (uiConfigData == undefined) {
    uiConfigData = {} as UIConfiguration;
  }
  useEffect(() => {
    if (uiConfigData !== undefined)
      document.title = uiConfigData.pageTitle;
  }, []);
  return (
    <UIConfigurationProvider config={uiConfigData}>
      <Box sx={{ display: "flex" }}>
        <Router>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<InstituteLogin />} />
            <Route path="/User/Login" element={<InstituteLogin />} />
            <Route path="/User/ForgotPassword" element={<ForgotPassword />} />
            <Route
              path="/institute/Registration"
              element={<InistitutionRegistration />}
            />
            <Route path="/institute/Home" element={<InistitutionHome />} />
            <Route path="/institute/Profile" element={<InistitutionProfile />} />
            <Route path="/institute/UploadStudents" element={<InistituteStudentUpload />} />
            <Route path="/institute/ExamResults" element={<InistituteExamResults />} />
            <Route path="/Student/Home" element={<StudentHome />} />
            <Route path="/Student/Exam" element={<StudentExam />} />
            <Route path="/institute/Students" element={<InistituteStudentList />} />
            <Route path="/Facilitator/Home" element={<FacilitatorHome />} />
            <Route path="/Facilitator/Institutions" element={<FacilitatorInstitutionsList />} />
            <Route path="/Facilitator/Students" element={<FacilitatorStudentList />} />

          </Routes>
        </Router>
      </Box>
    </UIConfigurationProvider>
  );
};
