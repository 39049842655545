import { ForgotPasswordControlState, IForgotPasswordModel } from './forgotPasswordModels';



export const getForgotPasswordControlState =
    (values: IForgotPasswordModel): ForgotPasswordControlState => {

        let controlState = new ForgotPasswordControlState();
        controlState.loginName.label.userName = 'User Name';

        return controlState;
    };



