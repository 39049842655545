import * as React from "react";
import { CommonConstants } from "../common/constants/common-constants";
import { IAPIResponse, IHTTPResponse } from "../common/models/httpModels";
import { HTTPService } from "../common/services/httpService";
import { IUserLoginInfo } from "./instituteLoginModels";

const httpService = new HTTPService();
const login = (request: IUserLoginInfo): Promise<IHTTPResponse<IAPIResponse<any>>> => {
  const url = `${CommonConstants.BaseAddress}${
    CommonConstants.ApiConstants.Login
  }`;
  return httpService.post(url,request);
};

const LoginService = {
  login,
};

export default LoginService;
