import * as React from 'react';
import { UIConfiguration } from "../models/ui-configuration-Models";
import { Context, FunctionComponent, ReactNode, useEffect } from "react";

type UIConfigurationContextProps = {
  children: ReactNode;

  config: UIConfiguration;
};
const intialData = {} as UIConfiguration;

export const UIConfigurationContext: Context<UIConfiguration> = React.createContext<UIConfiguration>(intialData);

export const UIConfigurationProvider = (props: UIConfigurationContextProps): JSX.Element => {
  const { children, config } = props;
  return (
    <UIConfigurationContext.Provider value={config}>
      {children}
    </UIConfigurationContext.Provider>)
    ;
};
