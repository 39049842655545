import React from "react";
import { FunctionComponent } from "react";
import { UIConfigurationContext } from "../common/contexts/ui-configurationContext";
import { IForgotPasswordModel } from "./forgotPasswordModels";
import { FormType, withForm } from "../common/ui-form";
import { ForgotPasswordFieldSet } from "./forgotPasswordFieldSet";
import { Container, Paper } from "@mui/material";
import { getForgotPasswordControlState } from "./forgotPasswordControlState";
import { getForgotPasswordFormValidations } from "./forgotPasswordValidations";

const ForgotPasswordForm = withForm<
IForgotPasswordModel,
    FormType<IForgotPasswordModel>
>(ForgotPasswordFieldSet);




export const ForgotPassword: FunctionComponent = () => {


    const uiConfigData = React.useContext(UIConfigurationContext);
    const intialValues = (): IForgotPasswordModel => {
        return {
            loginName: '',
            program:uiConfigData.program
        };
    };
    return (<>
        <Container sx={{}}>
            <Paper variant="outlined" sx={{ padding: "24px", borderRadius: "0.625rem", mt: '2rem', bgcolor: '#fafafa' }}>
                <ForgotPasswordForm
                    enableReintialize
                    formValues={intialValues()}
                    getValidationSchema={(v: IForgotPasswordModel) =>
                        getForgotPasswordFormValidations(v)
                    }
                    getControlState={(v: IForgotPasswordModel) =>
                        getForgotPasswordControlState(v)
                    }
                />
            </Paper>
        </Container>
    </>);

}