
import { UIConfigurationData } from "../data/ui-configuration";
import { UIConfiguration } from "../models/ui-configuration-Models";

const get = (tenantName: string): UIConfiguration|undefined => {
  return  UIConfigurationData.find(i => i.tenantName === tenantName);
};


const UIConfigurationHelper = {

    get,
    
}

export default UIConfigurationHelper;