import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { red, yellow } from "@mui/material/colors";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { UIConfigurationContext } from "../../common/contexts/ui-configurationContext";
import React from "react";
export const InistituteDownloads: FunctionComponent = (props) => {
  const uiConfigData = React.useContext(UIConfigurationContext);
  const handleDownloadClick = (name: string, url: string) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ flex: "1 0 auto" }}>
        <MenuList style={{ paddingTop: '0', paddingBottom: '0' }}>
          {
            uiConfigData.downloads.map((item) =>

              <MenuItem style={{ paddingTop: '0', paddingBottom: '0' }}>
                <Button
                  onClick={() =>
                    handleDownloadClick(item.name, item.url)
                  }

                  size="large"
                  startIcon={item.type == 'pdf' ?
                    <PictureAsPdfIcon fontSize="inherit" sx={{ color: red[500] }} /> :
                    <FolderZipIcon fontSize="inherit" sx={{ color: yellow[800] }} />

                  }
                >
                  {item.title}
                </Button>
              </MenuItem>

            )
          }

        </MenuList>
      </CardContent>
    </Card >
  );
};
