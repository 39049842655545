import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { FunctionComponent } from "react";
import { green } from "@mui/material/colors";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { UIConfigurationContext } from "../../common/contexts/ui-configurationContext";
import React from "react";
export const InistituteStudentsTemplate: FunctionComponent = (props) => {
  const uiConfigData = React.useContext(UIConfigurationContext);
  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.download = `StudentListTemplate.xlsx`;
    link.href = uiConfigData.studentTemplate;
    link.click();
  };
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography component="div" variant="h5" sx={{ textAlign: "center" }}>
          Student Template
        </Typography>

        <br />
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={handleDownloadClick}
            size="large"
            startIcon={
              <FileDownloadIcon fontSize="inherit" sx={{ color: green[500] }} />
            }
            sx={{ color: green[500] }}
          >
            Download
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
