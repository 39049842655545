import * as Yup from "yup";
import { IForgotPasswordModel } from "./forgotPasswordModels";



export const getForgotPasswordFormValidations = (
    values: IForgotPasswordModel
): Yup.AnyObjectSchema => {
    const schema: Yup.SchemaOf<IForgotPasswordModel> = Yup.object()
        .shape({
            loginName: Yup.string().required("Login Name is Required"),
        })
        .defined();
    return schema;
};