import { LoginFieldSet } from "../login/loginFieldSet";
import { Container, Paper } from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import { FormType, withForm } from "../common/ui-form";
import { getInistitutionLoginFormValidations } from "./instituteLoginValidations";
import { getInstitueLoginControlState } from "./instituteLoginControlState";
import { CommonConstants } from "../common/constants/common-constants";
import { UIConfigurationContext } from "../common/contexts/ui-configurationContext";
import { IUserLoginInfo } from "./instituteLoginModels";
const InistitutionLoginForm = withForm<
    IUserLoginInfo,
    FormType<IUserLoginInfo>
>(LoginFieldSet);

export const InstituteLogin: FunctionComponent = () => {
    const uiConfigData = React.useContext(UIConfigurationContext);

    const intialValues = (): IUserLoginInfo => {
        return {
            userName: "",
            passWord: "",
            program: uiConfigData.program
        };
    };

    return (
        <>
            <Container sx={{}}>
                <Paper variant="outlined" sx={{ padding: "24px", borderRadius: "0.625rem", mt: '2rem', bgcolor: '#fafafa' }}>
                    <InistitutionLoginForm
                        enableReintialize
                        formValues={intialValues()}
                        getValidationSchema={(v: IUserLoginInfo) =>
                            getInistitutionLoginFormValidations(v)
                        }
                        getControlState={(v: IUserLoginInfo) =>
                            getInstitueLoginControlState(v)
                        }
                    />
                </Paper>
            </Container>
        </>
    );
};