import { IControlState } from "../common/ui-form";
import { ControlState } from "../common/ui-form/control-state";

export interface IForgotPasswordModel{
    loginName :string;
    program:string;
}
export interface IForgotPasswordControlState {
    loginName: IControlState;
}

export class ForgotPasswordControlState
    implements IForgotPasswordControlState {
        loginName: IControlState = new ControlState();
}