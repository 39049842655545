import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormType } from '../common/ui-form';
import { IForgotPasswordControlState, IForgotPasswordModel } from './forgotPasswordModels';
import { toast, ToastContainer } from 'react-toastify';
import { UIConfigurationContext } from '../common/contexts/ui-configurationContext';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordService from './forgotPasswordService';
import { LoadingButton } from '@mui/lab';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();



export const ForgotPasswordFieldSet: React.FunctionComponent<
    FormType<IForgotPasswordModel>
> = ({
    values,
    handleBlur,
    handleChange,
    errors,
    isValid,
    dirty,
    getValidationSchema,
    getControlState,
    isRequired,
    ...props
}) => {
        const controlState: IForgotPasswordControlState = getControlState(values);
        const navigate = useNavigate();
        const [loading,setLoading]= React.useState<boolean>(false);
        const forgotPasswordSubmit = async (
            forgotPasswordRequest: IForgotPasswordModel
        ): Promise<void> => {
            setLoading(true);
            await ForgotPasswordService.submit(forgotPasswordRequest)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0) {
                        toast.error(
                            result.data.errors[0].message
                        )
                    }
                    else if (result.data.data) {
                        toast.success(
                            `Credentials sent to your registered email address`
                        )

                        navigate("/user/login");
                    }
                })
                .catch((error: any) =>
                    toast.error(
                        `Error occurred while saving registration details - ${error}`
                    )
                ).finally(()=>{
                    setLoading(false);
                });
        };
        const handleSubmit = () => {
            // eslint-disable-next-line no-console
            if (isValid) {
                forgotPasswordSubmit(values);
            }

        };

        return (
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'rgb(0, 167, 111)' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot your password?
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField

                                margin="normal"
                                label={controlState.loginName.label.userName}
                                disabled={controlState.loginName.disable.userName}
                                onBlur={handleBlur}
                                value={values.loginName}
                                onChange={handleChange}
                                name="loginName"
                                error={!!errors.loginName}
                                helperText={errors.loginName}
                                fullWidth={true}
                                autoFocus
                            />

                            <LoadingButton
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isValid}
                                onClick={handleSubmit}
                                loading={loading}
                            >
                                Send Request
                            </LoadingButton>


                            <Grid container>
                                <Grid item xs>
                                    <Link href="/#/User/Login" variant="body2">
                                        Return to sign in
                                    </Link>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                </Container>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
            </ThemeProvider>
        );

    }