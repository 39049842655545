import { type } from "os";
import {
    ControlState,
    IControlState,
} from "../common/ui-form/control-state";


export interface IUserLoginInfo {
    userName: string;
    passWord: string;
    program:string;
}

export interface IUserLoginControlState {
    userName: IControlState;
    passWord: IControlState;
}

export class UserLoginControlState
    implements IUserLoginControlState {
    userName: IControlState = new ControlState();
    passWord: IControlState = new ControlState();
}